body {
  -khtml-user-select: auto !important;
  -o-user-select: auto !important;
  -moz-user-select: auto !important;
  -webkit-user-select: auto !important;
  user-select: auto !important;
}
html {
  -khtml-user-select: auto !important;
  -o-user-select: auto !important;
  -moz-user-select: auto !important;
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.card {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: linear-gradient(
      180deg,
      rgba(145, 70, 255, 1) 15%,
      rgba(0, 200, 175, 1) 125%
    );
    display: flex;
    flex-direction: column;
  }
  
  @media screen and (max-width: 512px) {
    .card {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
    }
  }
  
  .content {
    flex: 1 1 auto;
    min-height: 256px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .content .title-holder {
    color: #f0f0ff;
    text-align: center;
    margin-bottom: 24px;
  }
  
  .content .title-holder h1 {
    font-size: 36px;
    margin-bottom: 12px;
  }
  
  .content .title-holder p {
    font-size: 16px;
    line-height: 28px;
    color: #f0f0ff;
  }
  
  @media screen and (max-width: 768px) {
    .content .title-holder {
      max-width: 80%;
    }
  }

  .error {
    font-family: "Courier New", Courier, monospace;
    background-color: rgba(59, 59, 59, .65);
    border: 1px solid #cecece;
    padding: 0.75rem;
    border-radius: 8px;
    color: #cecece;
    margin: 2rem auto;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    font-size: 1rem;
    line-height: 1.6;
    text-align: left;
  }

  .glow {
    color: #FAB4FF;
    text-shadow: rgba(255, 255, 255, 0.2) 0 0 5px;
    text-decoration: underline dotted 1px;
  }